import React from "react";
import { toast } from "react-hot-toast";
import { Editor } from "react-draft-wysiwyg";
import { useForm } from "react-hook-form";
import { postCreate } from "service/ServiceNews";
import { validateFile } from "helpers/validateFile";
import { ReactComponent as Check } from "assets/icons/check.svg";
import { ReactComponent as ErrorIcon } from "assets/icons/times-circle.svg";
import { useTextEditor } from "hooks/useTextEditor";
import "styles/custom-file.css";
import { uploadFileGac } from "service/file-manager";

const TextEditor = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting, touchedFields, dirtyFields },
  } = useForm();
  const { editorState, onEditorStateChange } = useTextEditor();

  const prevImage = watch("img");

  const isFieldValid = (name) => {
    return !errors[name] && touchedFields[name] && dirtyFields[name];
  };

  const onSubmit = handleSubmit(async (data) => {
    console.log(data);

    const {
      titulo,
      bajada,
      etiqueta,
      descripcion,
      tipo,
      url,
      fijado,
      fecha_publicacion,
      img,
    } = data;

    try {
      const validationError = validateFile(img[0]);
      if (validationError !== true) {
        toast.error(validationError, { duration: 8000 });
        return;
      }

      let response = "";
      if (img[0]) {
        const formData = new FormData();
        formData.append("fileToUpload", img[0]);
        formData.append("uploadDir", "imagenes_intranet");
        response = await uploadFileGac(formData);
      }

      if (response.status === 200) {
        const payload = {
          titulo,
          bajada,
          etiqueta,
          descripcion,
          tipo,
          url,
          fijado,
          fecha_publicacion,
          img: response.data.data.fileUrl,
          id_img: response.data.data.fileUrl,
        };
        await postCreate(payload);
        toast.success("Noticia agregada");
        // setEditorState(EditorState.createEmpty());
        reset();
      }
    } catch (error) {
      toast.error("Ocurrió un error, inténtalo de nuevo");
      console.error(error);
    }
  });

  return (
    <form onSubmit={onSubmit} className="formCreate">
      <div className="group">
        <label htmlFor="titulo">Título</label>
        <div className="input-container">
          <input
            id="titulo"
            {...register("titulo", {
              required: "El campo Título es obligatorio",
            })}
            className={`input ${
              errors.titulo ? "invalid" : isFieldValid("titulo") ? "valid" : ""
            }`}
          />
          {errors.titulo ? (
            <ErrorIcon className="icon invalid-icon" />
          ) : (
            isFieldValid("titulo") && <Check className="icon valid-icon" />
          )}
        </div>
        {errors.titulo && (
          <p className="error-message">{errors.titulo.message}</p>
        )}
      </div>
      <section className="group-select">
        <div className="group">
          <label>Tipo de publicación</label>
          <div className="input-container">
            <select
              {...register("tipo", {
                required: "El campo tipo es obligatorio",
              })}
              className={`input ${errors.tipo ? "invalid" : "valid"}`}
            >
              <option value="">Seleccione un tipo</option>
              <option value="Pop up">Pop up</option>
              <option value="noticia interna">Noticia interna </option>
            </select>

            {errors.tipo ? (
              <ErrorIcon className="icon invalid-icon" />
            ) : (
              isFieldValid("tipo") && <Check className="icon valid-icon" />
            )}
          </div>
          {errors.tipo && (
            <p className="error-message">{errors.tipo.message}</p>
          )}
        </div>

        <div className="group">
          <label htmlFor="etiqueta">Categoría</label>
          <div className="input-container">
            <select
              id="etiqueta"
              {...register("etiqueta", {
                required: "El campo Categoría es obligatorio",
              })}
              className={`input ${
                errors.etiqueta
                  ? "invalid"
                  : isFieldValid("etiqueta")
                  ? "valid"
                  : ""
              }`}
            >
              <option value="">Seleccione una categoría</option>
              <option value="Comunicación Interna">Comunicación Interna</option>
              <option value="Fechas especiales">Fechas especiales</option>
              <option value="Promociones">Promociones</option>
              <option value="Calidad">Calidad</option>
              <option value="Talento Humano">Talento Humano</option>
            </select>
            {errors.etiqueta ? (
              <ErrorIcon className="icon invalid-icon" />
            ) : (
              isFieldValid("etiqueta") && <Check className="icon valid-icon" />
            )}
          </div>
          {errors.etiqueta && (
            <p className="error-message">{errors.etiqueta.message}</p>
          )}
        </div>
      </section>
      {watch("tipo") === "noticia interna" && (
        <div className="group">
          <label>Bajada</label>
          <input className="" {...register("bajada")} />
        </div>
      )}

      <div className="group">
        <label>Descripción</label>
        <Editor
          editorState={editorState}
          onEditorStateChange={(state) => onEditorStateChange(state, setValue)}
        />
      </div>

      <label className="custum-file-upload" htmlFor="file">
        <div className="icon_upload">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              fillRule="evenodd"
              d="M10 1a1 1 0 00-.707.293l-6 6A1 1 0 003 8v12a3 3 0 003 3h1a1 1 0 100-2H6a1 1 0 01-1-1V9h5a1 1 0 001-1V3h7a1 1 0 011 1v5a1 1 0 102 0V4a3 3 0 00-3-3h-8zM9 7H6.414L9 4.414V7zm5 8.5a2.5 2.5 0 015 0V17h1a2 2 0 110 4h-7a2 2 0 110-4h1v-1.5zm2.5-4.5a4.5 4.5 0 00-4.484 4.122A4.002 4.002 0 0013 23h7a4 4 0 00.984-7.878A4.5 4.5 0 0016.5 11z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
        <div className="text">
          <span>Clic para subir archivo</span>
        </div>
        <input
          type="file"
          id="file"
          accept="image/*"
          {...register("img", {
            required: "El campo Imagen es obligatorio",
          })}
        />
      </label>
      {errors.img && <p className="error-message">{errors.img.message}</p>}
      {prevImage && prevImage.length > 0 && (
        <img
          src={URL.createObjectURL(prevImage[0])}
          alt="image_upload"
          className="img-fluid"
          id="image"
          style={{
            height: "200px",
            objectFit: "contain",
            margin: "10px auto",
            display: "flex",
          }}
        />
      )}

      {/* options avanzadas */}

      <div className="group">
        <label>Publicación fijada (Campo opcional)</label>
        <label className="switch">
          <input type="checkbox" {...register("fijado")} />
          <span className="slider"></span>
        </label>
      </div>

      <section className="group-select">
        <div className="group">
          <label>Fecha de publicación (Campo opcional)</label>
          <input
            className=""
            type="datetime-local"
            {...register("fecha_publicacion")}
          />
        </div>

        <div className="group">
          <label>Link</label>
          <input className="" {...register("url")} />
        </div>
      </section>

      <div className="centrar">
        <button
          className="mt-3 btn-login"
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>{" "}
              Subiendo noticia...
            </>
          ) : (
            "Agregar"
          )}
        </button>
      </div>
    </form>
  );
};

export default TextEditor;
